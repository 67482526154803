<template>
    <div>
      <h1>Create a New Post</h1>
      <form @submit.prevent="createPost">
        <label for="title">Title:</label>
        <input v-model="title" type="text" id="title" required />
  
        <label for="body">Body:</label>
        <textarea v-model="body" id="body" required></textarea>
  
        <button type="submit">Submit</button>
      </form>
  
      <div v-if="response">
        <h2>Response:</h2>
        <pre>{{ response }}</pre>
      </div>
    </div>
  </template>
  
  <script>
  const { postData } = require('../../../apiGetData.js'); // Import the postData function
  
  export default {
    data() {
      return {
        title: '',
        body: '',
        response: null, // To store the API response
      };
    },
    methods: {
      async createPost() {
        const postDataObj = {

          cc : this.title,
          to : this.body 
        };
        const headers = {
            'Content-Type': 'application/json',
            'clientid': this.actid, // Dynamic client ID
            'Authorization': this.susertoken // Dynamic authorization token
        };
        try {
          // Call the postData function and pass the data
          this.response = await postData('getHoldings', postDataObj,headers);
          console.log('Post created:', this.response);
        } catch (error) {
          console.error('Failed to create post:', error);
        }
      },
    },
  };
  </script>
  