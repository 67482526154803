import { render, staticRenderFns } from "./DashBoard2.vue?vue&type=template&id=6fd1a510&scoped=true"
import script from "./DashBoard2.vue?vue&type=script&lang=js"
export * from "./DashBoard2.vue?vue&type=script&lang=js"
import style0 from "./DashBoard2.vue?vue&type=style&index=0&id=6fd1a510&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd1a510",
  null
  
)

export default component.exports