<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense  type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <v-alert v-if="alertGreen" dense  type="success" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <!-- d-none d-md-block -->
            <div class="pt-md-lg-16 pa-mg-lg-6 pa-sm-4 pa-md-lg-4 ">
                <!-- <v-switch x-small v-model="$vuetify.theme.dark" class="mt-n1 mr-2"></v-switch> -->
                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Total portfolio </v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Multiple brokers are holding shares, as shown here.
                                        </v-list-item-subtitle>
                                       
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>
                            
                            <!-- <v-select label="Filled" :items="filterr" @change="filterseg()" dense
                                rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 mt-n3 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            <v-menu class="" v-model="menuForDatePicker" left  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        
                                    </div>
                                     
                                    <p  v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                       
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur'}"  @click="GetDataWithYearSelector(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear'}"  @click="GetDataWithYearSelector(-1,'lyear')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0]}"  @click="GetDataWithYearSelector(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1]}"  @click="GetDataWithYearSelector(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2]}"  @click="GetDataWithYearSelector(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                       
                                    </div>
                                </v-card>
                                
                            </v-menu> -->
                           
                           
                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                          
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                           
                        </v-toolbar>
                        <v-divider></v-divider>
                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class=" pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card  class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                         
                                        <v-divider  class="mt-2" style="border:solid px #000000"
                                            width="30px" color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                         

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>
                                              
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                        
                            </v-row>
                        </div> -->
                        <!-- <v-text-field style="width: 100%;" class="tophundraedmutual d-md-none mt-4 mr-5" height="36px"
                            background-color="#F1F3F8" label="Search symbols" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card @click="chargerdialoge = true" class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                        <div class="display-flex row pt-6 pl-2"> 
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0" style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col> 
                            </v-row>
                        </div> -->
                        
                        <!-- <v-card class="mt-8" outlined  style=" height : 160px;width : 100%;overflow-x: scroll">
                            <div v-if=" loader == false" class="ml-n3 mt-4">
                                

                                <div class="ml-1" id="cal-heatmap"></div>
                                   
                             </div>
                            
                            <div v-else class="text-center mt-4">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                
                        </v-card> -->
                    </v-card>
                    <!-- :search="search" -->
                     <!-- {{ TableDataEq }} -->
                     <!-- <v-tabs
                        v-model="tabs"
                        color="black" >
                        <v-tab >
                             Equity
                        </v-tab>
                        <v-tab >
                             Mutual fund
                        </v-tab>
                        </v-tabs> -->
                        <!-- {{LogoJson[2].logo}} -->
                        <!-- {{SyncDate}} -->
                        
                        <p v-if="!btnShowForSync" class="text-right caption blacck--text mt-3">Last SYNC : <span class="caption grey--text">{{SyncDate.split(" ")[0].split("-")[2] + "/" + SyncDate.split(" ")[0].split("-")[1] + "/" + SyncDate.split(" ")[0].split("-")[0] }} {{ SyncDate.split(" ")[1] }}</span></p>
                        <v-container v-if="loader" fluid class="d-flex justify-center align-center" style="height: 65vh;">
                            <v-card  style="border: 1.5px #E9E8E8 solid" 
                                    class="elevation-0 px-4 d-flex justify-center align-center" width="100%" height="65vh">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-container>
                        <v-container v-else-if="btnShowForSync" fluid class="d-flex justify-center align-center" style="height: 75vh;">
                            <v-card  style="border: 1.5px #E9E8E8 solid" 
                                    class="elevation-0 px-4 d-flex justify-center align-center" width="100%" height="65vh">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                        <p class="font-weight-bold caption black--text">Syncing is required to retrieve your holdings <br/> data from multiple brokers.
                                            <v-tooltip color="black" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="black"
          dark
          v-bind="attrs"
          v-on="on"
          size="18"
        >
        mdi-information-outline
        </v-icon>
      </template>
      <span>Here, we display your holdings, 
        <br/>including those with other brokers.</span>
    </v-tooltip>
                                            
                                        </p>
                                        <v-btn v-if="btnShowForSync" flat solo elevation="0" class="text-capitalize white--text"
                                                rounded color="black" small :loading="btnloader" @click="redirecttocdsl()"
                                            >Sync</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-container>
                        
                        <v-expansion-panels v-else class="mt-5" style="z-index: 0;" v-model="panel">
                            <v-expansion-panel
                            v-for="(item,key,ind) in TableDataEq"
                            :key="ind"
                            >
                        <!-- {{panel}} {{ ind}} -->

                            <v-expansion-panel-header>
                                <!-- {{LogoJson[i].logo}} -->
                                 <!-- {{ ImageURL }} -->
                                <!-- <img alt="img" class="shrink" :src="LogoJson[i].logo" width="5%" height="" /> -->

                                <div style="width: 200px;" class="d-flex">
                                    <img alt="img" class="shrink"  :src="`${ImageURL}/${item.profile.logopath}`"  width="10%" height="" />
                                    <span class="captionTitle caption ml-4 " :title="key"> {{ key }} </span>
                                    <span class="caption  "  > ({{ item.summary.length }}) </span>
                                </div>
                                <!-- {{calculatePercentageMainBar(Value.total)}} -->
                                <div v-if="panel != ind"  class="progress-bar ml-4">

                                    <div class="progress-bar" :style="{ width: key == keys ? calculatePercentageMainBar(Value.total) + '%' : 0 + '%', backgroundColor: colorArray[0] }" v-for="(Value,keys,index) in TableDataEq" :key="index" >
                                    <!-- {{Value}} -->
                                        <!-- {{calculatePercentageMainBar(Value.total)}} -->
                                       
                                            <v-tooltip color="black"  bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                            
                                            
                                                
                                            
                                                    <v-card color="transparent" class="elevation-0" v-bind="attrs" v-on="on" width="100%" height="20px">

                                                    </v-card>
                                                    </template>
                                                    <span class="caption">{{(Value.total).toFixed(2) }}</span>

                                                    </v-tooltip>
                                                    <p class="mr-2 text-right black--text caption" style="margin-top: -2px;">{{(Value.total).toFixed(2)}}</p>
                                        </div>
                                         <!-- {{Value.total}} -->
                                        
                                    </div>
                                    <div v-else  class="progress-bar ml-4">
                                    <!-- {{Value.token}} -->
                                     <div :style="{ width: calculatePercentage(((Number(ltpvalarray[Value.token].lp) - Number(Value.lastTradedPrice)) * Number(Value.units)), arrayVal(item.summary)) + '%' , backgroundColor: colorArray[i] }" v-for="(Value,i) in item.summary" :key="i" >
                                        
                                        <v-tooltip color="black"  bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                        
                                        
                                            
                                        
                                                <v-card color="transparent" class="elevation-0" v-bind="attrs" v-on="on" width="100%" height="20px">

                                                </v-card>
                                                </template>
                                                <span class="caption">{{Value.isinDescription}}<br/>{{((Number(ltpvalarray[Value.token].lp) - Number(Value.lastTradedPrice)) * Number(Value.units)).toFixed(2)}}</span>

                                                </v-tooltip>
                                     </div>


                                    <!-- <v-tooltip v-for="Value,i in item.summary" :key="i" color="black"  bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                        
                                        
                                            
                                            <div v-bind="attrs"
                                            v-on="on" class="progress-linehover" :style="{ width: 100 + '%' , backgroundColor: '#5bc0de' }">
                                            {{((ltpvalarray[Value.token] && Number(ltpvalarray[Value.token].lp) ? Number(ltpvalarray[Value.token].lp) : 0 - Number(Value.lastTradedPrice)) * Number(Value.units))}}
                                            {{arrayVal(item.summary)}}
                                        {{calculatePercentage(((ltpvalarray[Value.token] && Number(ltpvalarray[Value.token].lp) ? Number(ltpvalarray[Value.token].lp) : 0 - Number(Value.lastTradedPrice)) * Number(Value.units)), arrayVal(item.summary))}}
                                        </div>

                                        </template>
                                        <span>frfr</span>
                                    </v-tooltip> -->
                                     
                                     
                                    
                                    
                                </div>
                                

                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!-- {{item.summary}} -->
                                <!-- :sort-by="['isinDescription']"  :sort-desc="[false]"  -->
                                <v-data-table ref="dataTableRef"   :loading="loader" mobile-breakpoint
                                    hide-default-footer height="300px" fixed-header :headers="HeaderFortable"
                                    :items="item.summary" :items-per-page="item.summary.length"
                                    style="border: 1px solid #EFEEF3;z-index: -1;" class="d-none d-md-block mt-4 elevation-0 rounded-lg">
                                    <template v-slot:[`item.symbol`]="{ item , index }">
                                        <div class="d-flex">
                                            <v-card  :style="{ width: '6px', height: '20px', backgroundColor: colorArray[index] }">

                                            </v-card>
                                            <span class="font-weight-regular caption ml-2">
                                                <!-- {{index}} -->
                                                 {{ item.symbol }}</span>
                                        </div>
                                        
                                    </template>

                                    <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                        <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                    </template> -->
                                     
                                    <template v-slot:[`item.units`]="{ item }">
                                        <span class="font-weight-regular caption">{{ item.units }}</span>
                                    </template>
                                    <template v-slot:[`item.ltp`]="{ item }">
                                        <span class="font-weight-regular caption mb-0">{{ ltpvalarray[item.token].lp }}</span><br>
                                    </template>
                                    <template v-slot:[`item.pnl`]="{ item }">
                                        <span :class="{
                                            'green--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) > 0,
                                            'red--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) < 0,
                                            'black--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) === 0
                                            }"
                                            class="font-weight-regular caption">
                                            {{ ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)).toFixed(2) }} 
                                            (
                                            {{ (((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) / Number(item.lastTradedPrice)) * 100 ).toFixed(2) }} %
                                            )
                                        </span>
                                    </template>
                                    <template v-slot:[`item.value`]="{ item }">
                                        <!-- <span class="font-weight-regular caption mb-0">{{ Number(item.lastTradedPrice)   }}</span><br>
                                        <p class="font-weight-regular caption grey--text mb-0">{{ Number(item.lastTradedPrice)   * Number(item.units)}}</p> -->
                                        <p class="font-weight-regular caption  mb-0">{{ (Number(ltpvalarray[item.token].lp) * Number(item.units)).toFixed(2) }}</p>

                                    </template>
                                    <!-- <template v-slot:[`item.dpname`]="{ item }">
                                        <span class="font-weight-bold caption">{{ item.dpname.split(" ")[0] }}</span>
                                    </template> -->

                                    <!-- <template v-slot:[`item.type_in`]="{ item }">
                                        <span class="font-weight-regular caption">{{ item.type_in   }}</span>
                                    
                                    </template> -->

                                    <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                        <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                    </template> -->

                                    <template v-slot:[`item.date_time`]="{ item }">
                                        <!-- <span class="font-weight-regular caption">{{ (item.date_time.split(".")[0]).split(" ")[0] }}</span><br>
                                        <span class="font-weight-regular  caption">{{ (item.date_time.split(".")[0]).split(" ")[1] }}</span> -->
                                        <p class="font-weight-regular caption grey--text mb-0">{{ (Number(ltpvalarray[item.token].lp) * Number(item.units)).toFixed(2) }}</p>
                                    </template> 
                                </v-data-table>
                                <v-card v-for="(item,indexVal) in item.summary " :key="indexVal" style="border-top: 5px #F1F3F8 solid;"
                                    class="elevation-0 d-md-none px-4" width="100%">
                                    
                                        <div>
                                            
                                            <div class="d-flex row pa-4">
                                                <v-card class="mr-2" :style="{ width: '6px', height: '20px', backgroundColor: colorArray[indexVal] }">

                                                </v-card>
                                            <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item.symbol }}
                                            </p>
                                            
                                            <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Qty :
                                                <span class="mb-0  font-weight-regular" style="color: #000000;">{{ item.units }}</span></p>
                                            </div>

                                            <v-divider class="mb-2"></v-divider>
                                                <v-row class="px-2">
                                                
                                                <v-col class="d-flex" cols="12">

                                                    <div>
                                                <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">LTP :
                                                <span class="mb-0  font-weight-regular" style="color: #000000;">{{ ltpvalarray[item.token].lp }}</span>
                                                <!-- <span class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item.EXPIRY_DATE }}</span> -->

                                                </p>
                                                <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Value : <span
                                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ (Number(ltpvalarray[item.token].lp) * Number(item.units)).toFixed(2) }}</span>
                                                </p>
                                            </div>

                                            <p class="mb-3 body-2 font-weight-regular ml-auto" style="color: #666666;">P&L : <span :class="{
                                                'green--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) > 0,
                                                'red--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) < 0,
                                                'black--text': ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)) === 0
                                                }"
                                                class="font-weight-regular caption">
                                                {{ ((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) * Number(item.units)).toFixed(2) }} 
                                                (
                                                {{ (((ltpvalarray[item.token].lp - Number(item.lastTradedPrice)) / Number(item.lastTradedPrice)) * 100 ).toFixed(2) }} %
                                                )
                                            </span>
                                            </p>

                                        </v-col>
                                        
                                        </v-row>
                                        </div>
                                    
                                </v-card>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-tabs-items v-model="tabs">
                            <v-tab-item>
                                <v-data-table :sort-by="['folioNo']"  :sort-desc="[false]" ref="dataTableRef"    :loading="loader" mobile-breakpoint
                                hide-default-footer height="450px" fixed-header disable-sort :headers="HeaderFortable"
                                :items="TableDataEq" :items-per-page="TableDataEq.length"
                                style="border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg">

                               
                                

                                <template v-slot:[`item.symbol`]="{ item }">
                                    <span v-if="item.type_in == 'equities'" class="font-weight-regular caption">{{ item.symbol }}</span>
                                    <!-- <span v-else class="font-weight-regular caption">{{ item.folioNo }}</span> -->
                                </template>

                                <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.units`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.units }}</span>
                                </template>

                                <!-- <template v-slot:[`item.dpname`]="{ item }">
                                    <span class="font-weight-bold caption">{{ item.dpname.split(" ")[0] }}</span>
                                </template> -->

                                <!-- <template v-slot:[`item.type_in`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.type_in   }}</span>
                                </template> -->

                                <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.date_time`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.date_time }}</span>
                                </template> 
                     </v-data-table>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table ref="dataTableRef"    :loading="loader" mobile-breakpoint
                                hide-default-footer height="450px" fixed-header disable-sort :headers="HeaderFortableMF"
                                :items="TableDataMF" :items-per-page="TableDataMF.length"
                                style="border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg">

                               
                                

                                <template v-slot:[`item.symbol`]="{ item }">
                                    <span v-if="item.type_in == 'equities'" class="font-weight-regular caption">{{ item.symbol }}</span>
                                    <span v-else class="font-weight-regular caption">{{ item.folioNo }}</span>
                                </template>

                                <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.units`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.units }}</span>
                                </template>

                                <template v-slot:[`item.dpname`]="{ item }">
                                    <span class="font-weight-bold caption">{{ item.dpname.split(" ")[0] }}</span>
                                </template>

                                <template v-slot:[`item.type_in`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.type_in   }}</span>
                                   
                                </template>

                                <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.date_time`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.date_time }}</span>
                                </template> 
                     </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>

                    
                    
                     
                </v-col>
                
            </div>
            <!-- mobile -->
            <!-- "d-md-none" -->
             
        </v-container>
    </v-app>
</template>
  
<script>
 
 import axios from 'axios';
import { apiurlotherPort } from '../../../Api.js'


// import LegendLite from 'cal-heatmap/plugins/LegendLite';
 
export default {
     
    data() {
        return {
            ChartValue : [],
            ImageURL : '',
            btnloader : false,
            fromshow : '',
            filterdata_unpl : 'Equity',
            yearsarray : [],
            cal : null,
            highlightyearsValue : 'cur',
            fromdate : [],
            maxval : '',
            panel: null,
            menuForDatePicker : false,
            menuForDatePickerMobile : false,
            TotalAssetsValue : 0,
            TaxesAndCharges : 0,
            SyncDate : '',
            UnRealishedPnl : 0,
            colorArray : [
                '#5bc0de', '#85c88a', '#f0ad4e', '#d9534f', '#8bc34a', '#ff5722', '#9c27b0', '#3f51b5',
                '#4caf50', '#ff9800', '#03a9f4', '#e91e63', '#cddc39', '#ffeb3b', '#009688', '#673ab7',
                '#00bcd4', '#795548', '#607d8b', '#2196f3', '#00c853', '#b71c1c', '#f57c00', '#3d5afe',
                '#f44336', '#81c784', '#d32f2f', '#fbc02d', '#689f38', '#f06292', '#3949ab', '#1e88e5',
                '#ffb300', '#7b1fa2', '#6d4c41', '#1b5e20', '#8e24aa', '#64b5f6', '#ffcc80', '#ab47bc',
                '#ef5350', '#c2185b', '#5e35b1', '#00acc1', '#e0e0e0', '#9e9e9e', '#4db6ac', '#aed581',
                '#ffab91', '#43a047', '#ffb74d', '#d81b60', '#ffca28', '#f48fb1', '#8d6e63', '#e57373',
                '#ff6f00', '#ff5252', '#8eacbb', '#00e676', '#b0bec5', '#00acc1', '#ffc107', '#4caf50',
                '#bf360c', '#ff1744', '#006064', '#f57f17', '#d500f9', '#ff4081', '#00e5ff', '#dce775',
                '#b39ddb', '#18ffff', '#ffab00', '#536dfe', '#e65100', '#ff6d00', '#2e7d32', '#4e342e',
                '#d50000', '#6a1b9a', '#1a237e', '#ff8f00', '#0091ea', '#bf360c', '#0277bd', '#880e4f',
                '#ff9100', '#1de9b6', '#dd2c00', '#212121', '#82b1ff', '#ff80ab', '#37474f', '#ff5722',
                '#2979ff', '#ff5252', '#ff4081', '#8bc34a', '#ab47bc', '#ff9100', '#1a237e', '#ffb300',
                '#7b1fa2', '#6d4c41', '#1b5e20', '#8e24aa', '#64b5f6', '#ffcc80', '#ab47bc', '#ef5350',
                '#c2185b', '#5e35b1', '#00acc1', '#e0e0e0', '#9e9e9e', '#4db6ac', '#aed581', '#ffab91',
                '#43a047', '#ffb74d', '#d81b60', '#ffca28', '#f48fb1', '#8d6e63', '#e57373', '#ff6f00',
                '#ff5252', '#8eacbb', '#00e676', '#b0bec5', '#00acc1', '#ffc107', '#4caf50', '#bf360c',
                '#ff1744', '#006064', '#f57f17', '#d500f9', '#ff4081', '#00e5ff', '#dce775', '#b39ddb',
                '#18ffff', '#ffab00', '#536dfe', '#e65100', '#ff6d00', '#2e7d32', '#4e342e', '#d50000',
                '#6a1b9a', '#1a237e', '#ff8f00', '#0091ea', '#bf360c', '#0277bd', '#880e4f', '#ff9100',
                '#1de9b6', '#dd2c00', '#212121', '#82b1ff', '#ff80ab', '#37474f', '#ff5722', '#2979ff',
                '#ff5252', '#ff4081', '#8bc34a', '#ab47bc', '#ff9100', '#1a237e', '#ffb300', '#7b1fa2',
                '#6d4c41', '#1b5e20', '#8e24aa', '#64b5f6', '#ffcc80', '#ab47bc', '#ef5350', '#c2185b',
                '#5e35b1', '#00acc1', '#e0e0e0', '#9e9e9e', '#4db6ac', '#aed581', '#ffab91', '#43a047',
                '#ffb74d', '#d81b60', '#ffca28', '#f48fb1', '#8d6e63', '#e57373', '#ff6f00', '#ff5252',
                '#8eacbb', '#00e676', '#b0bec5', '#00acc1', '#ffc107', '#4caf50', '#bf360c', '#ff1744',
                '#006064', '#f57f17', '#d500f9', '#ff4081', '#00e5ff', '#dce775', '#b39ddb', '#18ffff',
                '#ffab00', '#536dfe', '#e65100', '#ff6d00', '#2e7d32', '#4e342e', '#d50000', '#6a1b9a',
                '#1a237e', '#ff8f00', '#0091ea', '#bf360c', '#0277bd', '#880e4f', '#ff9100', '#1de9b6',
                '#dd2c00', '#212121', '#82b1ff', '#ff80ab', '#37474f', '#ff5722', '#2979ff', '#ff5252',
                '#ff4081', '#8bc34a', '#ab47bc', '#ff9100', '#1a237e', '#ffb300', '#7b1fa2', '#6d4c41',
                '#1b5e20', '#8e24aa', '#64b5f6', '#ffcc80', '#ab47bc', '#ef5350', '#c2185b', '#5e35b1',
                '#00acc1', '#e0e0e0', '#9e9e9e', '#4db6ac', '#aed581', '#ffab91', '#43a047', '#ffb74d',
                '#d81b60', '#ffca28', '#f48fb1', '#8d6e63', '#e57373', '#ff6f00', '#ff5252', '#8eacbb',
                '#00e676', '#b0bec5', '#00acc1', '#ffc107', '#4caf50', '#bf360c', '#ff1744', '#006064',
                '#f57f17', '#d500f9', '#ff4081', '#00e5ff', '#dce775', '#b39ddb', '#18ffff', '#ffab00',
                '#536dfe', '#e65100', '#ff6d00', '#2e7d32', '#4e342e', '#d50000', '#6a1b9a', '#1a237e',
                '#ff8f00', '#0091ea', '#bf360c', '#0277bd', '#880e4f', '#ff9100', '#1de9b6', '#dd2c00',
                '#212121', '#82b1ff', '#ff80ab', '#37474f', '#ff5722', '#2979ff', '#ff5252', '#ff4081',
                '#8bc34a', '#ab47bc', '#ff9100', '#1a237e', '#ffb300', '#7b1fa2', '#6d4c41', '#1b5e20',
                '#8e24aa', '#64b5f6', '#ffcc80', '#ab47bc', '#ef5350', '#c2185b', '#5e35b1', '#00acc1',
                '#e0e0e0', '#9e9e9e', '#4db6ac', '#aed581', '#ffab91', '#43a047', '#ffb74d', '#d81b60',
                '#ffca28', '#f48fb1', '#8d6e63', '#e57373', '#ff6f00', '#ff5252', '#8eacbb', '#00e676',
                '#b0bec5', '#00acc1', '#ffc107', '#4caf50', '#bf360c', '#ff1744', '#006064', '#f57f17',
                '#d500f9', '#ff4081', '#00e5ff', '#dce775', '#b39ddb', '#18ffff', '#ffab00', '#536dfe',
                '#e65100', '#ff6d00', '#2e7d32', '#4e342e', '#d50000', '#6a1b9a', '#1a237e', '#ff8f00',
                '#0091ea', '#bf360c', '#0277bd', '#880e4f', '#ff9100', '#1de9b6', '#dd2c00', '#212121',
                '#82b1ff', '#ff80ab', '#37474f', '#ff5722', '#2979ff', '#ff5252', '#ff4081', '#8bc34a',
                '#ab47bc', '#ff9100', '#1a237e', '#ffb300', '#7b1fa2', '#6d4c41', '#1b5e20', '#8e24aa',
                '#64b5f6', '#ffcc80', '#ab47bc', '#ef5350', '#c2185b', '#5e35b1', '#00acc1', '#e0e0e0',
                '#9e9e9e', '#4db6ac', '#aed581', '#ffab91', '#43a047', '#ffb74d', '#d81b60', '#ffca28',
                '#f48fb1', '#8d6e63', '#e57373', '#ff6f00', '#ff5252', '#8eacbb', '#00e676', '#b0bec5',
                '#00acc1', '#ffc107', '#4caf50', '#bf360c', '#ff1744', '#006064', '#f57f17', '#d500f9',
                '#ff4081', '#00e5ff', '#dce775', '#b39ddb', '#18ffff', '#ffab00', '#536dfe', '#e65100',
                '#ff6d00', '#2e7d32', '#4e342e', '#d50000', '#6a1b9a', '#1a237e', '#ff8f00', '#0091ea',
                '#bf360c', '#0277bd', '#880e4f', '#ff9100', '#1de9b6', '#dd2c00', '#212121', '#82b1ff',
                '#ff80ab', '#37474f', '#ff5722', '#2979ff', '#ff5252', '#ff4081', '#8bc34a', '#ab47bc',
                '#ff9100', '#1a237e', '#ffb300', '#7b1fa2', '#6d4c41', '#1b5e20', '#8e24aa', '#64b5f6',
                '#ffcc80', '#ab47bc', '#ef5350', '#c2185b', '#5e35b1', '#00acc1', '#e0e0e0', '#9e9e9e',
                '#4db6ac', '#aed581', '#ffab91', '#43a047', '#ffb74d', '#d81b60', '#ffca28', '#f48fb1',
                '#8d6e63', '#e57373', '#ff6f00', '#ff5252', '#8eacbb', '#00e676', '#b0bec5', '#00acc1'
            ],
            RealishedPnl : 0,
            ltpvalarray : [],
            FromDateForApi : '',
            ToDateForApi : '',
            susertoken : '',
            actid : '',
            cname : '',
            ltparray : [],
            minval: '',
            tabs : null,
            loader : true,
            mobileNumber : '',
            alert : false,
            alertGreen : false,
      intervalId: null,
 
            rangeColr : ['#ff6e6e'  , '#5aae61'],
            rangeValue : [-0.01,0.01],
            Nodata : false,
            msg : '',
            LogoJson : [
               
                {
                    name : 'FYERS',
                    logo :  require('@/assets/FYERS.svg')
                },
                {
                    name : 'UPSTOX',
                    logo : require('@/assets/UPSTOX.svg') 
                },
                {
                    name : 'ZEBU',
                    logo : require('@/assets/ZEBU.svg')
                },
            ],
            HeaderFortableMF : [
            // { text: 'Company name', value: 'dpname', align: 'start', class: "headerfont"  },
            { text: 'Script Symbol', value: 'symbol', align: 'start', class: "headerfont"  },

            // { text: 'Qty', value: 'units', align: 'start', class: "headerfont"  },
            // { text: 'Type', value: 'type_in' , align: 'start', class: "headerfont" },
            // { text: 'Date & Time', value: 'date_time' , align: 'start', class: "headerfont" },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            // { text: 'BRATE', value: 'BRATE' , align: 'start', class: "headerfont" },
            // // { text: 'B Amt', value: 'buyamount' , align: 'start', class: "headerfont" },
            // { text: 'S Date', value: '' , align: 'start', class: "headerfont" },
            // { text: 'SQTY', value: 'SQTY' , align: 'start', class: "headerfont" },
            // // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            // { text: 'SRATE', value: 'SRATE' , align: 'start', class: "headerfont" },
            // { text: 'STCG', value: 'STCG' , align: 'start', class: "headerfont" },
            // { text: 'LTCG', value: 'LTCG' , align: 'start', class: "headerfont" },
            // { text: 'Net Qty', value: 'Updated_NETQTY' , align: 'start', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'start', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            btnShowForSync : false,
            HeaderFortable : [
            // { text: 'Company name', value: 'dpname', align: 'start', class: "headerfont"  },
            // { text: '', value: 'logo', align: 'start', class: "headerfont"  },
            { text: 'Script Symbol', value: 'symbol', align: 'start', class: "headerfont"  },

            { text: 'Qty', value: 'units', align: 'start', class: "headerfont"  },
            { text: 'LTP', value: 'ltp', align: 'start', class: "headerfont"  },
            { text: 'Value', value: 'value', align: 'start', class: "headerfont"  },

            { text: 'P&L', value: 'pnl', align: 'start', class: "headerfont"  },
            // { text: 'Type', value: 'type_in' , align: 'start', class: "headerfont" },
            // { text: 'Date & Time', value: 'date_time' , align: 'start', class: "headerfont" },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            // { text: 'BRATE', value: 'BRATE' , align: 'start', class: "headerfont" },
            // // { text: 'B Amt', value: 'buyamount' , align: 'start', class: "headerfont" },
            // { text: 'S Date', value: '' , align: 'start', class: "headerfont" },
            // { text: 'SQTY', value: 'SQTY' , align: 'start', class: "headerfont" },
            // // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            // { text: 'SRATE', value: 'SRATE' , align: 'start', class: "headerfont" },
            // { text: 'STCG', value: 'STCG' , align: 'start', class: "headerfont" },
            // { text: 'LTCG', value: 'LTCG' , align: 'start', class: "headerfont" },
            // { text: 'Net Qty', value: 'Updated_NETQTY' , align: 'start', class: "headerfont" },
            // { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            // { text: 'Realisedpnl', value: 'realisedpnl' , align: 'start', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            TableDataEq : [],
            TableDataMF : [],
            filterr: ['Equity','Fno','Commodity','Currency'],
            SymbolDataMatched : [],
        };
    }, 
    created() {
        this.ImageURL = apiurlotherPort
        // console.log(apiurlotherPort,this.ImageURL,"linkk");
        
        const url = new URL(window.location.href);
        var response = url.searchParams.get("response");
        if (response) {
            this.alertGreen = true
            this.msg = response
            setTimeout(() => {
                this.alertGreen = false
            }, 4000);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
 
        
            
    },
    methods : {
        arrayVal(value){
            // console.log(value,'FINAL1212');
            
            var FinalsamVal = 0
            if (value.length > 0) {
                
                for (let i = 0; i < value.length; i++) {
                    const element = value[i];
            // console.log(this.ltpvalarray[element.token].lp,'arrahuman');

                    var sumVal = ((Number(this.ltpvalarray[element.token].lp) - Number(element.lastTradedPrice)) * Number(element.units))
                    FinalsamVal += sumVal
                }
                
            }
            else{
                FinalsamVal = ((this.ltpvalarray[value[0].token].lp - Number(value[0].lastTradedPrice)) * Number(value[0].units))
                // console.log(FinalsamVal,'valvalvalvalvlvavlavala5');
            }
            // console.log(FinalsamVal,'FINAL1212');

            return FinalsamVal
        },
        calculatePercentage(value,value2) {
        // Calculate the percentage for each separate value
            // console.log('12312341234',Math.abs(Number((Math.abs(value) / value2) * 100).toFixed(2)));
            return Math.abs(Number((Math.abs(value) / value2) * 100).toFixed(2));
        },
        calculatePercentageMainBar(value) {
            // console.log( Math.abs(Number((Math.abs(value) / this.TotalAssetsValue) * 100).toFixed(2)),"lololklklklk");
            
            return Math.abs(Number((Math.abs(value) / this.TotalAssetsValue) * 100).toFixed(2));
        },
        decryptionFunction(payld) {
            var CryptoJS = require("crypto-js");

            const payload = payld;
            const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
            const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
            const encryptedData = payload;

            // Decrypt the data using AES
            const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
                iv,
                mode: CryptoJS.mode.CBC,
            });
            const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

            // // console.log('Decrypted Data:', decryptedData);
            return decryptedData;
        },
        ltpGetdata() {
            let data = JSON.stringify({
                "data": this.ltparray
            });

            let config = {
                method: 'post',
                url: 'https://asvr.mynt.in/bcast/GetLtp',
                headers: {
                'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {   
                // console.log(JSON.stringify(response.data));

                var resarray = []

                resarray = response.data.data;
                axiosthis.ltpvalarray   = resarray
                var LoopData = axiosthis.TableDataEq;
                var TotalAsset = 0
                for (const [key, value] of Object.entries(LoopData)) {
                    var valueAmount = 0
                    for (let i = 0; i < value.summary.length; i++) {
                        const element = value.summary[i];
                        // console.log(element,'finaldatdatdatdtatda');
                        // value.total
                        TotalAsset += Number(axiosthis.ltpvalarray[element.token].lp) * Number(element.units)
                        valueAmount += Number(axiosthis.ltpvalarray[element.token].lp) * Number(element.units)
                        
                        
                    }
                    value.total = valueAmount
                    // console.log(key,value.summary,'finaldatdatdatdtatda');
                    console.log(key,'finaldatdatdatdtatdavalue');
                }
                axiosthis.TotalAssetsValue = TotalAsset
                axiosthis.TableDataEq = LoopData
                // console.log(axiosthis.TableDataEq,axiosthis.TotalAssetsValue,'finaldatdatdatdtatdavalue');

                // axiosthis.TableDataEq = LoopData
                // console.log(LoopData,'finaldatdatdatdtatda');
                
                
                
                // LoopData.forEach((value, index,key) => {
                //     console.log(`Index: ${index}, Value: ${value}`,key);
                // });
                // var parsedobj = JSON.parse(JSON.stringify(resarray))
                // console.log("lists s", parsedobj)

                // for (let w = 0; w < axiosthis.desserts.length; w++) {

                //   var list = parsedobj.findIndex(o => o.token== axiosthis.desserts[w].Token);
                //   axiosthis.desserts[w]['ltpdata'] = parseFloat(parsedobj[list].lp)
                //   axiosthis.desserts[w]['pnl_ltp'] = axiosthis.desserts[w]['ltpdata'] - axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET']
                //   if (axiosthis.desserts[w]['buy_price'] != 0) {
                //   axiosthis.desserts[w]['pnl_per_ltp'] = (axiosthis.desserts[w]['pnl_ltp'] / axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET'] ) * 100
                //   }else{
                //   axiosthis.desserts[w]['pnl_per_ltp'] = 0

                //   }

                // }
                // console.log("lists", axiosthis.desserts);
                // axiosthis.selectedItem2 = true




                // console.log(axiosthis.ltpvalarray[adxiosthis.desserts[0].Token] , "test");
                // this.val1 = resarray[0].lp
                // this.val2 = resarray[1].lp
                // this.val3 = resarray[2].lp
                // for (let i = 0; i < axiosthis.desserts.length; i++) {
                //     if (parsedobj && parsedobj[axiosthis.desserts[i].Token] && axiosthis.desserts[i].Token == parsedobj[axiosthis.desserts[i].Token]?.token) {
                //     axiosthis.desserts[i]['ltp'] = parseFloat((parsedobj[axiosthis.desserts[i].Token].lp));

                //     if (axiosthis.desserts[i]['buy_price'] != 0) {
                //         axiosthis.desserts[i]['pnl_stat'] = parseFloat(((parseFloat(axiosthis.desserts[i]['ltp']) - axiosthis.desserts[i]['buy_price']) * axiosthis.desserts[i]['NET']).toFixed(2));
                        
                //         let totalInvest = axiosthis.desserts[i]['buy_price'] * axiosthis.desserts[i]['NET'];
                //         let pnlPercentage = parseFloat((axiosthis.desserts[i]['pnl_stat'] / totalInvest) * 100);
                //         axiosthis.desserts[i]['pnl_perc_stat'] = parseFloat(pnlPercentage.toFixed(2));
                //         axiosthis.desserts[i]['buy_val_stat'] = parseFloat(axiosthis.desserts[i]['ltp']) * axiosthis.desserts[i]['NET']
                //         axiosthis.desserts[i]['no_buy_val_stat'] = 0
                //         // let holdingsValueBuyPrice = 0
                //         //  holdingsValueBuyPrice += axiosthis.desserts[i].buy_val_stat || 0;
                //         //  axiosthis.headerval.holdings_value_buyprice = holdingsValueBuyPrice
                //     } else {
                //         axiosthis.desserts[i]['pnl_perc_stat'] = 0;
                //         axiosthis.desserts[i]['pnl_stat'] = 0;
                //         axiosthis.desserts[i]['no_buy_val_stat'] = parseFloat(axiosthis.desserts[i]['ltp']) * axiosthis.desserts[i]['NET'];
                //         axiosthis.desserts[i]['buy_val_stat'] = 0
                //         // let holdingsValuenoBuyPrice = 0
                //         //  holdingsValuenoBuyPrice += axiosthis.desserts[i].no_buy_val_stat || 0;
                //         //  axiosthis.headerval.holdings_value_no_buyprice = holdingsValuenoBuyPrice
                //     }

                //     } else {
                //     // Handle the case where parsedobj[axiosthis.desserts[i].Token] is undefined or doesn't match the condition
                //     }
                // }
               

                // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
                //   return accumulator + object.pnl_stat;
                // }, 0);
                // console.log(axiosthis.desserts,"test");


               
                // console.log(this.headerval.holdings_value_no_buyprice,this.headerval.holdings_value_buyprice, 'test2');


                // console.log(axiosthis.desserts, 'list1');

                // Create a dictionary for faster lookup
                // const resDict = {};
                // for (const res of resarray) {
                //   resDict[res.token] = res;
                // }

                // // Update axiosthis.desserts
                // for (let i = 0; i < axiosthis.desserts.length; i++) { 
                //   const dessert = axiosthis.desserts[i];
                //   const resObject = resDict[dessert.Token];

                //   if (resObject) {
                //     dessert['ltp'] = resObject.lp;
                //     dessert['pnl'] = parseFloat(((parseInt(dessert['ltp']) - dessert['buy_price']) * dessert['updated_qty']).toFixed(2));

                //     let totalInvest = dessert['buy_price'] * dessert['updated_qty'];
                //     let pnlPercentage = parseFloat((dessert['pnl'] / totalInvest) * 100);
                //     dessert['pnl_perc'] = parseFloat(pnlPercentage.toFixed(2));
                //   }
                // }
                //    this.headerval.holdings_value_no_buyprice = this.desserts.reduce((accumulator, object) => {
                // return accumulator + object.present_no_buy_price;
                //   }, 0);
                //   console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
                //   // ........................................................................................
                //   this.headerval.holdings_value_buyprice = this.desserts.reduce((accumulator, object) => {
                //     return accumulator + object.present_with_buy_price;
                //   }, 0);
                //   console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
                // ........................................................................................
                // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
                //   return accumulator + object.pnl_stat;
                // }, 0);
                // console.log(this.desserts[0].pnl, this.desserts[1].pnl, this.desserts[2].pnl, this.headerval.total_pnl, 'this.headerval.total_invested');
                // ........................................................................................
                // let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
                // this.headerval.pnl_perc = (valofpnl).toFixed(2)



                // axiosthis.selectedItem2 = true

                // console.log(this.headerval.pnl_perc,this.headerval.total_pnl,"pnl_perc_stat");

                })
                .catch((error) => {
                console.log(error);
                axiosthis.loader = false
            axiosthis.alert = true
            axiosthis.msg = error
            setTimeout(() => {
                axiosthis.alert = false
            }, 4000);
                });

            },
           
        redirecttocdsl(){
            this.btnloader = true
            const axios = require('axios');
            let data = JSON.stringify({
            "mobile": this.mobileNumber
            });

            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.178:5891/,
            url: `${apiurlotherPort}/login/auth`,

            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
            let axiosthis = this
            axios.request(config)
            .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosthis.btnloader = false
            window.location.assign(`${response.data.redirectionurl}`)
            })
            .catch((error) => {
            console.log(error);
            axiosthis.loader = false
            axiosthis.alert = true
            axiosthis.msg = error
            setTimeout(() => {
                axiosthis.alert = false
            }, 4000);
            });

        },
        MountedDData(){
            this.susertoken = localStorage.getItem("usession");
            this.actid = localStorage.getItem("userid");
            var mobiledata = this.decryptionFunction(localStorage.getItem("profile_data"))
            this.mobileNumber = JSON.parse(mobiledata).client_data.MOBILE_NO
         
            let data = JSON.stringify({
            "mobileNo": this.mobileNumber
            });

            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.178:5891/broker_dateils_get_new1',
            url: `${apiurlotherPort}/broker_dateils_get`,

            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
            let axiosthis = this
            axios.request(config)
            .then((response) => {
                if (response.data.msg != "Not register") {
                    // console.log(response.data.msg.equities);
                    axiosthis.TableDataEq = response.data.msg.equities
                    const data =  axiosthis.TableDataEq

                    // Initialize an array to hold all the summary values
                    const allSummaries = [];

                    // Iterate over each company in the data
                    Object.keys(data).forEach(company => {
                        // Push each summary item into the allSummaries array
                        data[company].summary.forEach(summaryItem => {
                            allSummaries.push(summaryItem);
                        });
                        data[company]['key'] = company
                    });
                    // console.log(allSummaries);
                    for (let i = 0; i < allSummaries.length; i++) {
                        var element = allSummaries[i]
                        axiosthis.ltparray.push({ "exch": element.exch, "token": element.token })
                    }
                    axiosthis.SyncDate = response.data.msg.sync_datetime
                    Object.keys(axiosthis.TableDataEq).forEach(key => {
                        axiosthis.TableDataEq[key].summary.sort((a, b) => 
                            a.symbol.localeCompare(b.symbol)
                        );
                    });
                    // console.log(axiosthis.TableDataEq,"LOLOLOLLOJLJLJOJOJO");
                    
                    axiosthis.ltpGetdata()
                    axiosthis.intervalId = setInterval(() => {
                        axiosthis.ltpGetdata()  
                    }, 5000);
                }else if(response.data.msg == 'error occurred'){
                    axiosthis.loader = false
                    axiosthis.alert = true
                    axiosthis.msg = response.data.msg
                    setTimeout(() => {
                        axiosthis.alert = false
                    }, 4000);
                    
                }else{
                    axiosthis.btnShowForSync = true
                }
            
            // if (response.data.msg.summary != []) {
            //     for (let i = 0; i < response.data.msg.summary.length; i++) {
            //         const element = response.data.msg.summary[i];
            //         if (element.type_in == 'equities') {
            //             axiosthis.TableDataEq.push(element)
            //         }else{
            //             axiosthis.TableDataMF.push(element)
            //         }
            //     }
                
            //     axiosthis.loader = false
            // }else{
            //     //
            //     axiosthis.loader = false

            // }

            axiosthis.loader = false
            })
            .catch((error) => {
            console.log(error);
            axiosthis.loader = false
            axiosthis.alert = true
            axiosthis.msg = error
            setTimeout(() => {
                axiosthis.alert = false
            }, 4000);
            });

         
        },
         
    }, 
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    mounted() { 
        this.MountedDData()
    }
};
</script>

<style lang="scss">
.progress-barValue{
    display: flex;
  height: 15px;
//   width: 100%;
  background-color: #ddd;
  border-radius: 0px 5px 5px 0px;
  padding-left: 10px;
  padding-right : 20px;
  overflow: hidden;
}
.progress-bar {
  display: flex;
  height: 15px;
  width: 96%;
//   background-color: #ddd;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}

.progress-green {
  background-color: #85c88a;
  height: 100%;
}

.progress-red {
  background-color: #d9534f;
  height: 100%;
}

.chartctn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-linehover:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
   
}
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
.captionTitle {
  display: inline-block;
  max-width: 175px; /* Adjust the width as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

 

</style>